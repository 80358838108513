import React from "react";

function DropdownSelect(props){
    return (
      <div className="container">
        <div className="input-group">
          <select className="form-select" id="inputGroupSelect01" onChange={props.onChange} defaultValue={props.defaultValue}>
            <option value={props.defaultValue}>Store Location...</option>
            {props.selection.map((entry, i) => {
              return <option key={i}>{entry}</option>
              }
            )}
          </select>
        </div>
      </div>
    )
}

export default DropdownSelect;