import axios from "axios";

const setQueriesByLocation = (location) => {
  // console.log("Location: ", location);
  if (location === "") {
    return {};
  }

  return {
    set: "/api/sales/" + location + "/set",
    special: "/api/sales/" + location + "/special",
    member: "/api/sales/" + location + "/member",
  };
};

const fetchRequests = (queries) => {
  const getRequests = {};
  Object.keys(queries).map((key) => {
    return (getRequests[key] = axios.get(queries[key]));
  });

  return getRequests;
};

export { setQueriesByLocation, fetchRequests };
