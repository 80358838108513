import React, { useState } from "react";
import { HashRouter, Routes, Route } from "react-router-dom";

import "./App.css";
import Layout from "./Layout";
import SignIn from "../pages/SignIn";
import Sales from "../pages/Sales";

function App() {
  const [token, setToken] = useState();

  if (!token || token === "noToken") {
    return <SignIn title="HRMS" setToken={setToken} token={token} />;
  }

  return (
    <div className="App">
      <HashRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Sales />} />
          </Route>
        </Routes>
      </HashRouter>
    </div>
  );
}

export default App;
