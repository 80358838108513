import React from "react";
/**
 * Gets nested data using an array of keys corresponding to the keys at each respective level of the nested object
 *
 *
 * @param {*} dataObject is a JSON style object
 * @param {*} dataLabels is an array of keys where each index corresponds to the nested level of the dataObject
 * @return {*} data value paired with the last key (bottom level) of the dataObject
 */
function getNestedData(dataObject, dataLabels) {
  if (dataLabels.length === 1) {
    return dataObject[dataLabels[0]];
  } else {
    return getNestedData(
      dataObject[dataLabels[0]],
      dataLabels.slice(1, dataLabels.length)
    );
  }
}

/**
 *
 *
 * @param {*} props
 * @return {*}
 */
function Table(props) {
  let caption = props.caption;
  let headings = props.headings;
  let dataLabels = props.dataLabels;
  let tableData = props.tableData;

  return (
    <div className="table-responsive col-md-4">
      <table className="table table-sm">
        <caption className="caption-top">{caption}</caption>
        <thead>
          <tr>
            {headings.map((h, i) => {
              return <th key={i}>{h}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {tableData.map((d, j) => {
            return (
              <tr key={j}>
                {dataLabels.map((l, k) => {
                  return <td key={k}>{getNestedData(d, l)}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default Table;
