import React from "react";
import { useState } from "react";

function UploadModal(props) {
  const [files, setFiles] = useState("");

  const handleChange = (event) => {
    setFiles(event.target.value);
    console.log("input name: " + event.target.name);
    console.log("input value: " + event.target.value);
    // setFiles(values => ({...values, [event.target.name]:event.target.value}))
  };

  const submitFiles = async (event) => {
    return document.getElementById(props.formID).submit();
  };

  const handleSubmit = async (event) => {
    console.log("You are here");
    let result = 0;
    result = await submitFiles();
    console.log("result: ", result);
    return result;
  };

  return (
    <div className="container">
      {/* <!-- Button trigger modal --> */}
      <button
        type="button"
        className="btn btn-primary"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
      >
        {props.buttonText}
      </button>

      {/* <!-- Modal --> */}
      <div
        className="modal"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                {props.title}
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">{props.body}</div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                id="submitButton"
                form={props.formID}
                className="btn btn-primary"
                onClick={() => {
                  document.getElementById(props.formID).requestSubmit();
                }}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UploadModal;
