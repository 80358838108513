import { Link } from "react-router-dom";

function Header(props) {
  return (
    <div className="container">
      <h2 className="display-5">{props.title}</h2>
    </div>
  );
}

export default Header;
