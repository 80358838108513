import Tables from "../components/Tables";
import DropdownSelect from "../components/DropdownSelect";
import UploadModal from "../components/UploadModal";
import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { setQueriesByLocation, fetchRequests } from "../helpers/requests";

/* eslint-env jquery */
function Sales() {
  // GET store locations
  let [responses, setResponses] = useState({});
  let [storeLocation, setStoreLocation] = useState("");
  let [storeLocations, setStoreLocations] = useState([]);
  let [queries, setQueries] = useState(setQueriesByLocation(storeLocation));

  let getRequests = fetchRequests(queries);

  const handleChange = useCallback((event) => {
    // console.log("event.target.value: ", event.target.value);
    setStoreLocation(event.target.value);
    setQueries(setQueriesByLocation(event.target.value));
  }, []);

  const getSetStoreLocations = async () => {
    return axios
      .get("/api/sales/locations")
      .then((res) => setStoreLocations(res.data))
      .catch((err) => {
        console.log("err: ", err);
      });
  };

  useEffect(() => {
    function getStoreLocations() {
      let responses = axios.get("/api/sales/locations").then((response) => {
        setStoreLocations(response.data);
      });
      return responses;
    }
    getStoreLocations();
  }, []);

  const requestResponsesOfLocation = () => {
    // Reference for making multiple simultaneous GET requests: https://www.storyblok.com/tp/how-to-send-multiple-requests-using-axios
    function getAllResponses() {
      // console.log("Requesting responses of current selection: ", queries);
      let allResponses = {};
      axios
        .all(Object.values(fetchRequests(queries)))
        .then(
          axios.spread((...r) => {
            Object.keys(getRequests).map(
              (key, i) => (allResponses[key] = r[i].data)
            );

            Object.keys(allResponses).map((key) =>
              setResponses((prevState) => {
                return {
                  ...prevState,
                  [key]: allResponses[key],
                };
              })
            );
          })
        )
        .catch((err) => {
          alert(err.message);
        });
      return allResponses;
    }
    return getAllResponses;
  };

  useEffect(() => {
    setResponses(requestResponsesOfLocation()());
  }, [storeLocation]);

  const submitFiles = (event) => {
    return document.getElementById("uploadForm").submit();
  };

  const handleSubmit = async (event) => {
    if (document.getElementById("formInput").value === "") {
      alert("File(s) not selected.");
      return;
    }

    async function doSubmit() {
      await submitFiles();
      $("#exampleModal").modal("hide");
      document.getElementById("formInput").value = "";
      return;
    }

    doSubmit()
      .then(() => setTimeout(getSetStoreLocations, 2000))
      .then(() => setTimeout(requestResponsesOfLocation, 2000))
      .catch((err) => {
        console.log("ERROR: ", err.message);
      });
  };

  return (
    <div className="container">
      <div className="container">
        <div className="row g-0 justify-content-center">
          <div className="col col-6">
            <DropdownSelect
              defaultValue={""}
              onChange={(e) => handleChange(e)}
              selection={storeLocations}
            />
          </div>
          <div className="col col-2">
            <UploadModal
              title="Upload Files (.csv)"
              formID="uploadForm"
              formAction="/api/upload/sales"
              body={
                <form
                  id="uploadForm"
                  method="POST"
                  action="/api/upload/sales"
                  encType="multipart/form-data"
                  onSubmit={handleSubmit}
                >
                  <input
                    id="formInput"
                    type="file"
                    name="file"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    multiple
                  />
                </form>
              }
              buttonText="Upload Files"
            />
          </div>
        </div>
      </div>
      <Tables
        id="tables"
        tableHeadings={[
          ["Date", "Count"],
          ["Date", "Count"],
          ["Date", "Count"],
        ]}
        tableDataLabels={[
          [["_id", "Tanggal"], ["totalSales"]],
          [["_id", "Tanggal"], ["totalSales"]],
          [["_id", "Tanggal"], ["totalSales"]],
        ]}
        tableMetaDatas={responses}
      />
    </div>
  );
}

export default Sales;
