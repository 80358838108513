import React from "react";

function SignInFailureText(props) {
  if (props.indicator === "noToken") {
    return "No matching credentials";
  } else {
    return null;
  }
}

export default SignInFailureText;
