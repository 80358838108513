import React from "react";
import Table from "./Table";
/**
 * Create a <div> element containing multiple tables.
 *
 * @param {*} props {headings, dataLabels, tableMetaDatas}
 * tableHeadings is an array of arrays with each array containing headings corresponding to a specific table.
 * tableDataLabels is an array of arrays with each array containing data labels corresponding key labels in the object from which data is being extracted
 * 
 * @return {*} 
 */
function Tables(props) {
  return (
    <div className="row">
      {Object.entries(props.tableMetaDatas).map(([key, value], i) => {
        return (
          <Table
            key={i}
            caption={key}
            headings={props.tableHeadings[i]}
            dataLabels={props.tableDataLabels[i]}
            tableData={value}
          />
        );
      })}
    </div>
  );
}

export default Tables;
