import "./App.css";
import React from "react";
import { Outlet } from "react-router-dom";
import Header from "./Header";
// import Footer from "./Footer";
import logo from "../images/logo-white-border.png";

const Layout = () => {
  return (
    <>
      <div className="container">
        <Header
          title={
            <div
              className="d-flex flex-row justify-content-center"
              style={{ height: "8rem" }}
            >
              <div className="align-self-center flex-fill py-2">
                <h1 className="text-end ">HRMS</h1>
              </div>
              <div className="d-flex justify-content-start flex-fill py-3">
                <img src={logo} alt="myEurekaLogo" />
              </div>
            </div>
          }
        />
        <div className={"gy-2 gx-3 align-items-center px-4"}>
          <Outlet />
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default Layout;
