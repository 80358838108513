import { useState, useEffect, useCallback } from "react";
import axios from "axios";

import SignInFailureText from "../components/SignInFailureText";

async function loginUser(credentials) {
  return axios.get(
    "/api/signin/" + credentials.username + "/" + credentials.password
  );
}

function SignIn(props) {
  const [response, setResponse] = useState("");
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setResponse(
      await loginUser({
        username,
        password,
      })
    );
  };

  useEffect(() => {
    if (response) {
      props.setToken(response.data.token);
    }
  }, [response]);

  const handleUsername = useCallback((e) => {
    setUserName(e.target.value);
  }, []);

  const handlePassword = useCallback((e) => {
    setPassword(e.target.value);
  }, []);

  return (
    <div className="container position-relative" style={{ height: "50rem" }}>
      <div
        className="container card shadow-lg position-absolute top-50 start-50 translate-middle"
        style={{ width: "24rem", height: "20rem" }}
      >
        <div className="mt-4 text-center">
          <h2>
            <b>{props.title}</b>
          </h2>
          <p style={{ color: "red" }}>
            <SignInFailureText indicator={props.token} />
          </p>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="container">
            <label htmlFor="emailInput" className="form-label"></label>
            <input
              type="email"
              className="form-control"
              id="emailInput"
              placeholder="email"
              onChange={(e) => handleUsername(e)}
            />
          </div>

          <div className="container mb-3">
            <label htmlFor="passwordInput" className="form-label"></label>
            <input
              type="password"
              className="form-control"
              id="passwordInput"
              placeholder="password"
              onChange={(e) => handlePassword(e)}
              autoComplete="off"
            />
          </div>

          <div className="container text-center">
            <button className="btn btn-primary" type="submit">
              Sign In
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default SignIn;
